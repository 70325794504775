import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { handleError } from '../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../reducers/UiReducer';
import { notify } from '../reducers/NotifierReducer';
import { closeCitationFile } from '../reducers/DialogsReducer';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DialogContent } from '@mui/material';
import { getService } from 'reducers/service';
import { getCitationCourt } from 'reducers/CitationReducer';
import { makeAddress } from 'utils/functions';

const useStyles = makeStyles(theme => ({
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  textField: {
    width: 600,
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  dialog: {
    '& $content': {
      margin: 0,
      padding: 0,
      background: 'red !important',
    },
  },
}));

function AddCitationFile(props) {
  const classes = useStyles();
  const { wsClient, user, citationCourts } = props;
  const { type } = props.data;
  const username = user?.userData?.user?.Username;
  const service = getService('default-agency');
  const reportService = getService('reportcatalog');
  const Agencies = props.dictionary.Agencies || [];
  const Cities = props.dictionary.Cities || [];
  const [text, setText] = useState('');
  const [event, setEvent] = useState({});
  const textFieldRef = useRef(null);
  const [cityCode, setCityCode] = useState('Benton');
  const [formData, setFormData] = useState({});
  const [selectedCourt, setSelectedCourt] = useState({});
  const [reportList, setReportList] = useState([]);
  const [reportListLoading, setReportListLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const info = props.data.info;
    if (type == 'edit' && info?.CitationDefinitionID) {
      const {
        CourtStreet,
        CourtCity,
        CourtState,
        CourtZip,
        CourtAreaCode,
        CourtPhone,
        CourtType,
      } = info;
      const address = makeAddress(CourtStreet, CourtCity, CourtState, CourtZip);

      const number =
        CourtPhone?.length > 3 ? `${CourtPhone.slice(0, 3)}-${CourtPhone.slice(3)}` : '';
      const phone = `${CourtAreaCode ? '(' + CourtAreaCode + ')' : ''} ${number} `;
      const form = {
        FileName: info.FileName || '',
        Description: info.Description || '',
        AgencyID: info.AgencyID || '',
        ptsCityID: info.ptsCityID || '',
        IsDefault: info.IsDefault || false,
        NumberOfViolation: info.NumberOfViolation || null,
        IsWarning: info.IsWarning || false,
        NumberOfWarning: info.NumberOfWarning || '',
        County: info.County || '',
        MultipleViolation: info.MultipleViolation || '',
        JsonDefinition: info.JsonDefinition || '',
        FormType: info.FormType || '',
        CreatedBy: info.CreatedBy || '',
        UpdatedBy: user || '',
        CourtID: info.CourtID || null,
        CourtAddress: address,
        CourtPhone: phone,
        CourtType: CourtType || null,
        AgencyEmail: info.AgencyEmail || null,
        AgencyWebsite: info.AgencyWebsite || null,
        ApplicationName: info.ApplicationName || 'platinum_plus',
        ReportType: info.ReportType || 'regular',
      };
      setCityCode(info.City);
      setFormData(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.info, type]);
  useEffect(() => {
    getReportList();
  }, []);
  useEffect(() => {
    dispatch(getCitationCourt());
    // eslint-disable-next-line
  }, []);
  console.log({ formData });

  const getReportList = async () => {
    setReportListLoading(true);
    try {
      const reportList = await reportService.find({
        query: {
          type: 'RMSC',
          IsDeleted: 0,
        },
      });
      console.log('reportList', reportList);

      setReportList(reportList.data);
    } catch (err) {
      props.handleError(err, 'Error, Unable to retrieve report list');
    } finally {
      setReportListLoading(false);
    }
  };
  const close = () => {
    props.closeCitationFile();
  };

  const save = async () => {
    props.showSpinner();
    try {
      //   await addEventNote(text, ptsEventID);
      delete formData.CourtAddress;
      delete formData.CourtPhone;
      delete formData.CourtType;
      const reportType = formData.ReportType || 'regular';
      const applicationName = reportType === 'rdlc' ? 'platinum_plus' : formData.ApplicationName;
      if (type == 'edit') {
        const id = props.data?.info?.CitationDefinitionID;
        await service.patch(id, {
          ...formData,
          ReportType: reportType,
          ApplicationName: applicationName,
          Path: 'AgencyBasedUnitSetting',
          UpdatedBy: username,
        });
        props.notify('File Updated', 'success');
      } else {
        await service.create({
          ...formData,
          ReportType: reportType,
          ApplicationName: applicationName,
          CreatedBy: username,
          UpdatedBy: username,
        });
        props.notify('New File Created', 'success');
      }
    } catch (err) {
      props.handleError(err, 'Error, File not saved.', err);
    }
    props.hideSpinner();
    close();
  };
  const handleFormChange = (name, data, prepared = false) => {
    let newForm = { ...formData };
    if (prepared) {
      newForm = { ...newForm, ...prepared };
    } else {
      newForm[name] = data;
    }

    setFormData({ ...newForm });
  };
  const handleCourtSelection = court => {
    if (!court) {
      const newForm = { ...formData };

      newForm['CourtID'] = null;
      newForm['CourtAddress'] = '';
      newForm['CourtPhone'] = '';

      setFormData({ ...newForm });
      return;
    }
    const { Street_Address, Court_type, City, State, Zip_Code, Area_Code, Phone_Number } = court;
    const address = makeAddress(Street_Address, City, State, Zip_Code);
    const number =
      Phone_Number?.length > 3 ? `${Phone_Number.slice(0, 3)}-${Phone_Number.slice(3)}` : '';
    const phone = `${Area_Code ? '(' + Area_Code + ')' : ''} ${number} `;
    const newForm = { ...formData };

    newForm['CourtID'] = court.SQLSysID;
    newForm['CourtAddress'] = address;
    newForm['CourtPhone'] = phone;
    newForm['CourtType'] = Court_type;

    setFormData({ ...newForm });
  };
  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          disabled={!formData.FileName}
          autoFocus
          onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderRegularForm = () => {
    return (
      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item xs={12} sm={3}>
          <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
            <InputLabel htmlFor="unit-sort-options" className={classes.selectLabel}>
              Application Name
            </InputLabel>
            <Select
              native
              value={formData.ApplicationName}
              onChange={ev => handleFormChange('ApplicationName', ev.target.value)}
              label="Application Name"
              inputProps={{ id: 'app-name-options' }}>
              <option value="platinum_plus">Platinum Plus</option>
              <option value="win_justice">Win Justice</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            //fullWidth
            size="small"
            style={{ width: '100%' }}
            type="text"
            value={formData.FileName}
            label="File Name"
            //error={error}
            variant="outlined"
            onChange={e => handleFormChange('FileName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            //fullWidth
            size="small"
            style={{ width: '100%' }}
            type="text"
            value={formData.Description}
            label="Description"
            //error={error}
            variant="outlined"
            onChange={e => handleFormChange('Description', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="combo-box-demo"
            size="small"
            autoHighlight
            autoSelect
            value={{ AgencyID: formData.AgencyID || '' }}
            onChange={(event, newValue) => {
              handleFormChange('AgencyID', newValue?.AgencyID || '');
            }}
            options={[{ AgencyID: '' }, ...Agencies]}
            getOptionLabel={option => option.AgencyID || ''}
            renderOption={option => option.AgencyID || '-'}
            getOptionSelected={(option, value) => option.AgencyID === value.AgencyID}
            style={{ width: '100%' }}
            renderInput={params => <TextField {...params} variant="outlined" label="Agency" />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            //fullWidth
            size="small"
            style={{ width: '100%' }}
            type="text"
            value={formData.FormType}
            label="Form Type"
            //error={error}
            variant="outlined"
            onChange={e => handleFormChange('FormType', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.IsDefault || false}
                onChange={e => handleFormChange('IsDefault', e.target.checked)}
              />
            }
            label="Default"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            //fullWidth
            size="small"
            style={{ width: '100%' }}
            type="number"
            value={formData.NumberOfViolation || 0}
            label="Number of Violation"
            //error={error}
            variant="outlined"
            onChange={e => handleFormChange('NumberOfViolation', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.IsWarning || false}
                onChange={e => {
                  const newdata = { NumberOfWarning: '', IsWarning: e.target.checked };

                  handleFormChange(false, false, newdata);
                }}
              />
            }
            label="Warning Allowed"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            //fullWidth
            size="small"
            style={{ width: '100%' }}
            type="number"
            value={formData.NumberOfWarning || ''}
            label="Number of Warnings"
            //error={error}
            disabled={formData.IsWarning ? false : true}
            variant="outlined"
            onChange={e => handleFormChange('NumberOfWarning', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="combo-box-demo"
            size="small"
            autoHighlight
            autoSelect
            value={{ Code: cityCode || '' }}
            onChange={(event, newValue) => {
              setCityCode(newValue?.Code || '');
              handleFormChange('ptsCityID', newValue?.ptsCityID || '');
            }}
            options={[{ ptsCityID: '', Code: '' }, ...Cities]}
            getOptionLabel={option => option.Code || ''}
            renderOption={option =>
              option.CityDescription ? (
                <span>
                  {option.CityDescription}
                  <strong>{option.State ? ' - ' + option.State : ''}</strong>
                </span>
              ) : (
                '-'
              )
            }
            getOptionSelected={(option, value) => option.Code === value.Code}
            style={{ width: '100%' }}
            renderInput={params => <TextField {...params} variant="outlined" label="City" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            //fullWidth
            size="small"
            style={{ width: '100%' }}
            type="text"
            value={formData.County}
            label="County"
            //error={error}
            variant="outlined"
            onChange={e => handleFormChange('County', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="combo-box-demo"
            size="small"
            autoHighlight
            autoSelect
            value={
              citationCourts.find(court => court.SQLSysID === formData.CourtID) || {
                Court_Name: '',
                SQLSysID: '',
              }
            }
            onChange={(event, newValue) => {
              handleCourtSelection(newValue);
              // handleFormChange('CourtID', newValue?.SQLSysID || '');
            }}
            options={[{ Court_Name: '', SQLSysID: '' }, ...citationCourts]}
            getOptionLabel={option => option.Court_Name || ''}
            renderOption={option => option.Court_Name || ''}
            getOptionSelected={(option, value) => option.SQLSysID === value.SQLSysID}
            style={{ width: '100%' }}
            renderInput={params => <TextField {...params} variant="outlined" label="Court" />}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            style={{ width: '100%' }}
            type="text"
            value={formData.CourtType || ''}
            label="Court Type"
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            // disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            style={{ width: '100%' }}
            type="text"
            value={formData.CourtAddress || ''}
            label="Court Address"
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            // disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            //fullWidth
            size="small"
            style={{ width: '100%' }}
            type="text"
            value={formData.CourtPhone || ''}
            label="Court Phone"
            //error={error}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            // disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            style={{ width: '100%' }}
            type="text"
            value={formData.AgencyEmail}
            label="Agency Email"
            variant="outlined"
            onChange={e => handleFormChange('AgencyEmail', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            style={{ width: '100%' }}
            type="text"
            value={formData.AgencyWebsite}
            label="Agency Website"
            variant="outlined"
            onChange={e => handleFormChange('AgencyWebsite', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextField
            id="outlined-multiline-static"
            style={{ width: '100%' }}
            label="Citation Definition"
            variant="outlined"
            multiline
            rows={4}
            value={formData.JsonDefinition}
            onChange={e => handleFormChange('JsonDefinition', e.target.value)}
          />
          {/* <TextField
          //fullWidth
          size="small"
          type={'text'}
          value={formData.JsonDefinition}
          label="Citation Definition"
          //error={error}
          multiline
          rows={4}
          variant={'outlined'}
        /> */}
        </Grid>
      </Grid>
    );
  };

  const renderRDLCForm = () => {
    return (
      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            id="combo-box-demo"
            size="small"
            autoHighlight
            autoSelect
            loading={reportListLoading}
            loadingText="Loading reports..."
            value={{ ReportID: formData.FileName }}
            onChange={(event, newValue) => {
              handleFormChange('FileName', newValue?.ReportID || '');
            }}
            options={reportList}
            getOptionLabel={option => option.ReportID || ''}
            renderOption={option => (
              <span>
                <b>{option.ReportID}</b> - {option.ReportTitle}
              </span>
            )}
            getOptionSelected={(option, value) => option.ReportID == value}
            style={{ width: '100%' }}
            renderInput={params => (
              <TextField
                {...params}
                required
                error={!formData.FileName}
                helperText={!formData.FileName ? 'Report ID is required' : ''}
                variant="outlined"
                label="Report ID"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextField
            //fullWidth
            size="small"
            style={{ width: '100%' }}
            type="text"
            value={formData.Description}
            label="Description"
            //error={error}
            variant="outlined"
            onChange={e => handleFormChange('Description', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="combo-box-demo"
            size="small"
            autoHighlight
            autoSelect
            value={{ AgencyID: formData.AgencyID || '' }}
            onChange={(event, newValue) => {
              handleFormChange('AgencyID', newValue?.AgencyID || '');
            }}
            options={[{ AgencyID: '' }, ...Agencies]}
            getOptionLabel={option => option.AgencyID || ''}
            renderOption={option => option.AgencyID || '-'}
            getOptionSelected={(option, value) => option.AgencyID === value.AgencyID}
            style={{ width: '100%' }}
            renderInput={params => <TextField {...params} variant="outlined" label="Agency" />}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.IsDefault || false}
                onChange={e => handleFormChange('IsDefault', e.target.checked)}
              />
            }
            label="Default"
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <Dialog
      onClose={close}
      title={'Add Citation File'}
      open={true}
      actions={renderActions()}
      //   className={classes.dialog}

      // toolbar={false}
      // draggable={false}
      fullWidth
      maxWidth="lg">
      <DialogTitle>{type == 'edit' ? 'Edit' : 'Add'} Citation File</DialogTitle>
      <DialogContent className="py-2">
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
              <InputLabel htmlFor="unit-sort-options" className={classes.selectLabel}>
                Report Type
              </InputLabel>
              <Select
                native
                value={formData.ReportType || 'regular'}
                onChange={ev => handleFormChange('ReportType', ev.target.value)}
                label="Report Type"
                inputProps={{ id: 'report_type' }}>
                <option value="regular">Regular</option>
                <option value="rdlc">RDLC</option>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {formData.ReportType == 'rdlc' ? renderRDLCForm() : renderRegularForm()}
      </DialogContent>
      <DialogActions>
        <div className=" mb-1 mr-3">{renderActions()}</div>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {
    dictionary: state.dictionary,
    citationCourts: state.citations.citationCourts,
    wsClient: state.websocket,
    user: state.user,
  };
};
export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  handleError,
  notify,
  closeCitationFile,
})(AddCitationFile);
